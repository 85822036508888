import React from "react";
import serviceS1 from "@/images/cyber.jpg";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>Cyber Security</h2>
      <p>
        At Solxk, we are committed to delivering top-notch cyber security
        solutions to protect your digital assets. Our team of experts utilizes
        industry-leading tools and technologies to identify vulnerabilities,
        mitigate risks, and secure your systems. We recognize the critical
        importance of protecting your digital assets against the ever-evolving
        landscape of cyber threats. Our comprehensive cybersecurity solutions
        are meticulously designed to assist businesses of all sizes in
        mitigating risks, protecting sensitive data, and maintaining regulatory
        compliance in an increasingly complex threat environment.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>planning & strategy</h3>
          <p>Services We Offer:</p>
          <ul>
            <li>
              {/* <i className="fa fa-check-square"></i>Maltego: A powerful tool for
              link analysis,
            </li> */}
              <i className="fa fa-check-square"></i>Penetration Testing
            </li>
            {/* <li>
            <i className="fa fa-check-square"></i>Vulnerability Assessment
            </li> */}
            {/* <i className="fa fa-check-square"></i>Burp Suite:Allows us to
              identify weaknesses
            </li> */}
            {/* <li>
              <i className="fa fa-check-square"></i>Nmap:discover hosts and
              services
            </li> */}
            <li>
              <i className="fa fa-check-square"></i>Application Source Code
              Reviews
            </li>
            {/* <li>
              <i className="fa fa-check-square"></i>Metasploit: find and fix
              security gaps proactively.
            </li> */}
            <li>
              <i className="fa fa-check-square"></i>Compliance-Based Offensive
              Requirements
            </li>
            {/* <li>
              <i className="fa fa-check-square"></i>GVM (OpenVAS): perform
              comprehensive scans...
            </li> */}
            <li>
              <i className="fa fa-check-square"></i>OSINT Maltego Integration
              with Training
            </li>
            <li>
              <i className="fa fa-check-square"></i>SIEM Solutions Impletations
            </li>
            <li>
              <i className="fa fa-check-square"></i>WAF Solutions Integrations
            </li>
            {/* <li>
              <i className="fa fa-check-square"></i>Threat Hunting
            </li> */}
            {/* <li>
              <i className="fa fa-check-square"></i>CyberSecurity Training
            </li> */}
          </ul>
        </div>
      </div>
      <p>
        Whether you are a startup seeking to establish a strong brand identity
        or an established business aiming to refresh your visual presence,
        Solxk is here to assist you. Contact us today to discuss your design
        needs and take the first step towards transforming your brand into a
        visual masterpiece.
      </p>
      <p>
        At Solxk, we recognize that the foundation of a successful web
        development project lies in meticulous planning. Before writing a single
        line of code or designing a single pixel, we invest time in
        understanding your business goals, target audience, and unique
        requirements. Our comprehensive planning process ensures that every
        aspect of your project is carefully considered and executed with
        precision.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
